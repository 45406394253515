import React from "react";
import PolygonIcon from "../icon/PolygonIcon";

const WebshopHeaderFAQ = ({
  faq,
  topMenuIsShown,
  isDark,
}: {
  faq: Array<{
    icon: JSX.Element;
    title: string;
    description: string;
  }>;
  topMenuIsShown?: boolean;
  isDark?: boolean;
}) => {
  const bgColor = isDark ? "bg-primary-10" : "bg-tertiary-25";

  return (
    <div
      style={{ zIndex: 30000 }}
      className={`absolute ${
        topMenuIsShown ? "t-[200px]" : "top-[70px]"
      } max-h-screen w-full py-4 ${bgColor} items-center justify-center hidden sm:flex md:flex lg:flex xl:flex`}
    >
      <div className='absolute -top-3 right-0  xl:right-[24.5%] lg:right-[15.5%] md:right-[14.5%] '>
        <PolygonIcon isDark={isDark} />
      </div>
      <div className='w-[80%] max-h-full flex flex-row flex-wrap py-5 overflow-scroll scrollbar-hide'>
        {faq.map((i) => {
          return (
            <div className='flex flex-row w-full lg:w-1/3 xl:w-1/3 md:w-1/3 text-xs mt-4'>
              <div
                className='mx-4 items-center justify-center rounded-full h-[50px] w-[50px] flex'
                style={{ backgroundColor: "rgba(145, 156, 139, 0.15)" }}
              >
                {i.icon}
              </div>
              <div className='w-3/5'>
                <div className='text-primary-dark font-semibold capitalize'>{i.title}</div>
                <div className='text-primary-50 mt-1 mb-3'>{i.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WebshopHeaderFAQ;
