import React from "react";

const UserIcon = () => {
  return (
    <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.66052 12.0675C1.94752 11.8159 2.34065 11.6666 2.76057 11.6666H13.2392C13.6592 11.6666 14.0523 11.8159 14.3393 12.0675C14.6433 12.3339 14.7999 12.6878 14.7999 13.0409V13.9373C14.7999 14.2006 14.7771 14.3439 14.7319 14.4434C14.3471 15.2898 12.6893 17.0666 7.9999 17.0666C3.31046 17.0666 1.65271 15.2898 1.26793 14.4434L1.26793 14.4434C1.22267 14.3439 1.1999 14.2006 1.1999 13.9373V13.0409C1.1999 12.6878 1.35653 12.3339 1.66052 12.0675ZM1.66052 12.0675L1.13884 11.4723L1.66052 12.0675Z'
        stroke='#919C8B'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.1031 4.8C12.1031 6.89868 10.3038 8.66667 7.99991 8.66667C5.69602 8.66667 3.89668 6.89868 3.89668 4.8C3.89668 2.70131 5.69602 0.933331 7.99991 0.933331C10.3038 0.933331 12.1031 2.70131 12.1031 4.8Z'
        stroke='#919C8B'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default UserIcon;
