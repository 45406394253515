import React from "react";

const HamburgerIcon = () => {
  return (
    <svg
      className='w-[38px] h-[38px]'
      viewBox='0 0 38 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 14.5H25M15.4 19.5H25M13 24.5H25'
        stroke='#919C8B'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default HamburgerIcon;
