import { Tooltip, useMediaQuery } from "@chakra-ui/react";
import usePopUpModal from "@gruene-brise/common-ui/lib/Modal";
import environment, { Environment } from "@gruene-brise/data-access/config/environment";
import { changeLanguage } from "@gruene-brise/data-access/i18n/i18n";
import useAuth0, { openRoutes } from "libs/data-access/src/lib/hooks/useAuth0";
import { useCartState } from "libs/data-access/src/lib/state/useCartState";
import { useGlobalState } from "libs/data-access/src/lib/state/useGlobalState";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Trans, useTranslation } from "react-i18next";
import { BsPower, BsX } from "react-icons/bs";
import BoxingIcon from "../icon/BoxingIcon";
import BusIcon from "../icon/BusIcon";
import CallIcon from "../icon/CallIcon";
import BloomwellLogo from "../icon/BloomwellLogo";
import HamburgerIcon from "../icon/HamburgerIcon";
import ShoppingCartIcon from "../webshop/ShoppingCartIcon";
import WebshopHeaderFAQ from "./WebshopHeaderFAQ";
import WebshopHeaderMobile from "./WebshopHeaderMobile";

export interface WebshopHeaderProp {
  showTopMenu?: boolean;
  isDark?: boolean;
}

interface TopMenuOption {
  jsx: JSX.Element;
  onPress?: () => void;
  link?: string;
}

function websiteWithPath(path: string) {
  return new URL(path, environment.websiteUrl).toString();
}

const WebshopHeader = ({ showTopMenu = true, isDark }: WebshopHeaderProp) => {
  const { push, pathname } = useRouter();
  const { isCartOpen, setCartOpen } = useCartState();
  const [isSmallScreen] = useMediaQuery("(min-width: 768px)");
  const [active, setActive] = useState<undefined | number>(0);
  const { language, setLanguage } = useGlobalState();
  const { t } = useTranslation();
  const isEnglish = language === "en";
  const { isLoggedIn, logOut, isDoctor } = useAuth0("webshop");
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const authorizationPath = useMemo(() => {
    return openRoutes.filter((i) => {
      return !(i === "/ncm" || i === "/");
    });
  }, []);

  useEffect(() => {
    if (!isSmallScreen) {
      setOpenMobileMenu(false);
    }
  }, [isSmallScreen]);

  const logoutOption = {
    jsx: (
      <Tooltip title={t("Log Out")!}>
        <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
          <BsPower size={20} />
        </div>
      </Tooltip>
    ),
    onPress: async () => {
      setCartOpen(false);
      await logOut("webshop");
    },
  };

  const loggedInOptions = [
    {
      jsx: (
        <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
          <Trans>My account</Trans>
        </div>
      ),
      onPress: () => {
        push("/account/personal-details");
      },
    },
    {
      jsx: (
        <Tooltip title={t("Log Out")!}>
          <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
            <Trans>Orders</Trans>
          </div>
        </Tooltip>
      ),
      onPress: () => {
        push("/account/order-history");
      },
    },
  ];

  const loggedOutOptions = [
    {
      jsx: (
        <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
          <Trans>Register</Trans>
        </div>
      ),
      onPress: () => {
        window.location.href =
          environment.environment == Environment.Production
            ? "https://bloomwell.de/de/registrierung/"
            : "https://identity.staging.algeacare.rocks/register";
      },
    },
    {
      jsx: (
        <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
          <Trans>Login</Trans>
        </div>
      ),
      onPress: () => {
        push("/signin");
      },
    },
  ];

  const topLeftMenu = useMemo(() => {
    if (isLoggedIn) {
      return loggedInOptions;
    }

    if (isDoctor) {
      return [logoutOption];
    }

    return loggedOutOptions;
  }, [isLoggedIn, isDoctor]);

  const topMenu: TopMenuOption[][] = [
    [
      {
        jsx: (
          <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
            <Trans>About Us</Trans>
          </div>
        ),
        onPress: () => {
          window.location.href = "https://bloomwell.de/de/ueber-uns/";
        },
      },
      {
        jsx: (
          <div className='hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
            <Trans>Contact</Trans>
          </div>
        ),
        onPress: () => {
          window.location.href = "https://bloomwell.de/de/kontakt/";
        },
      },
    ],

    [
      {
        jsx: (
          <div className='flex flex-row items-center gap-2'>
            <BusIcon />

            <div className='hidden xs:hidden lg:block'>
              <Trans>Fast delivery</Trans>
            </div>
          </div>
        ),
      },
      {
        jsx: (
          <div className='flex flex-row items-center gap-2'>
            <BoxingIcon />

            <div className='hidden xs:hidden lg:block'>
              <Trans>Live Tracking</Trans>
            </div>
          </div>
        ),
      },
    ],

    [
      ...topLeftMenu,
      {
        jsx: (
          <ReactCountryFlag
            countryCode={isEnglish ? "GB" : "DE"}
            svg
            style={{
              width: 15,
            }}
          />
        ),
        onPress: () => {
          open();
        },
      },
    ],
  ];

  const button = ({ title, isActive }: { title: JSX.Element; isActive?: boolean }) => {
    return (
      <div
        className={`
${schemeColor} font-medium px-4 py-1.5 hover:bg-primary-10 text-sm rounded-md ${
          isActive && isDark ? " bg-primary-10 !text-white" : isActive ? "bg-primary-10" : ""
        } hidden xs:hidden md:block transition-all duration-500`}
      >
        {title}
      </div>
    );
  };

  const secondMenu: TopMenuOption[][] = [
    [
      {
        jsx: <BloomwellLogo />,
        onPress: () => {
          push("/");
        },
      },
    ],

    [
      {
        jsx: <Trans>Start</Trans>,

        link: websiteWithPath("/"),
      },
      {
        jsx: <Trans>Live Bestand</Trans>,
        onPress: () => {
          push("/");
        },
      },
      {
        jsx: <Trans>Partner Pharmacies</Trans>,
        link: websiteWithPath("/partnerapotheken"),
      },
      {
        jsx: <Trans>For Doctors</Trans>,
        link: websiteWithPath("/doc-signin"),
      },
      {
        jsx: <Trans>Counselor</Trans>,
        link: websiteWithPath("/magazin"),
      },
      {
        jsx: <Trans>FAQ</Trans>,
        link: "https://support.gruenebrise.de/wissensdatenbank",
      },
    ],

    [
      ...(openMobileMenu || authorizationPath.includes(pathname.toLowerCase())
        ? [{ jsx: <div className='w-[50px]' /> }]
        : [
            // {
            //   jsx: <HeaderSearchIcon />,
            //   onPress: () => {
            //     push("/");
            //   },
            // },
            {
              jsx: <ShoppingCartIcon />,
              onPress: () => {},
            },
          ]),
      {
        jsx: (
          <div className='block xs:block lg:hidden'>
            {openMobileMenu ? <BsX size={30} className='text-primary-50' /> : <HamburgerIcon />}
          </div>
        ),
        onPress: () => {
          setOpenMobileMenu(!openMobileMenu);
        },
      },
    ],
  ];

  const faqStyleOption = "w-[60%]";

  const faq = [
    {
      icon: <img src='/images/leave.png' className={faqStyleOption} />,
      title: t("questions about products"),
      description: t("Everything about cultivars, product names and much more."),
    },
    {
      icon: <img src='/images/doc.png' className={faqStyleOption} />,
      title: t("Questions about the recipe"),
      description: t("Everything about cultivars, product names and much more."),
    },
    {
      icon: <img src='/images/weedcar.png' className={faqStyleOption} />,
      title: t("Problems with the access code"),
      description: t("Everything about cultivars, product names and much more."),
    },
    {
      icon: <img src='/images/leave.png' className={faqStyleOption} />,
      title: t("Exchange questions"),
      description: t("Everything about cultivars, product names and much more."),
    },
    {
      icon: <img src='/images/leave.png' className={faqStyleOption} />,
      title: t("shipping"),
      description: t("Everything about cultivars, product names and much more."),
    },
    {
      icon: <img src='/images/leave.png' className={faqStyleOption} />,
      title: t("Further questions"),
      description: t("Everything about cultivars, product names and much more."),
    },
  ];

  const refOne = useRef<any>(null);
  const { ConfirmationModal, open, close } = usePopUpModal();
  const bgColor = isDark ? "bg-white" : "bg-white";

  useEffect(() => {
    document.addEventListener(
      "click",
      (e: any) => {
        try {
          if (!refOne?.current?.contains?.(e.target) && active === secondMenu[1].length - 1) {
            setActive(undefined);
          }
        } catch {}
      },
      true,
    );

    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape" && active === secondMenu[1].length - 1) {
        setActive(undefined);
      }
    });

    return () => {
      try {
        document.removeEventListener("click", () => {});
        document.removeEventListener("keyup", () => {});
      } catch {}
    };
  }, [refOne, active, openMobileMenu, isSmallScreen]);
  const schemeColor = `${!isDark ? "text-primary" : "text-black"}`;

  return (
    <header
      className={`${bgColor} w-full ${
        showTopMenu ? "h-[120px]" : "h-[60px] md:h-[70px]"
      } font-gellix overflow-hidden transition-all duration-500 select-none`}
      style={{ zIndex: 200 }}
      ref={refOne}
      id='header-hide'
    >
      {showTopMenu && (
        <div className='flex flex-row justify-between px-[6%] items-center py-1 border-t-0 border-b-[1px] border-primary-alpha'>
          {topMenu.map((item, idx) => {
            return (
              <div
                key={"top-level" + idx.toString()}
                className={`flex-row flex items-center justify-center text-xs`}
              >
                {item.map((menu, idx2) => {
                  return (
                    <a
                      href={menu.link}
                      target='_blank'
                      rel='noreferrer'
                      key={"sub-level" + idx2.toString()}
                      onClick={() => menu?.onPress?.()}
                      className={`px-5 py-1 cursor-pointer text-grey rounded-md hover:bg-primary-10 ${
                        isDark ? "hover:text-black" : "hover:text-primary"
                      } transition-all duration-500`}
                    >
                      {menu.jsx}
                    </a>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      <div className='grid grid-cols-3 md:flex md:justify-between lg:px-[6%] items-center py-2 mt-2 md:mt-4 `'>
        {secondMenu.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`flex items-center ${idx === 1 ? "transparent lg:flex" : ""} ${
                idx === 2 ? "justify-self-end gap-5" : ""
              } md:justify-between px-3 ${schemeColor} text-sm z-20`}
            >
              {item.map((menu, index) => {
                return (
                  <a
                    href={menu.link}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                    onClick={() => {
                      if (menu.onPress) {
                        menu.onPress();

                        return;
                      }
                    }}
                    className={`${idx === 1 ? "lg:px-5" : ""} cursor-pointer text-xs}`}
                  >
                    {idx === 1
                      ? button({
                          title: menu.jsx,
                          isActive: Boolean(menu.link) && pathname.includes(menu.link!),
                        })
                      : menu.jsx}
                  </a>
                );
              })}
            </div>
          );
        })}
      </div>

      {openMobileMenu && (
        <WebshopHeaderMobile
          menu={secondMenu[1] as any}
          isActiveIndex={active}
          isDark={isDark}
          onClickNavItem={(index) => {
            setActive(index);
            setOpenMobileMenu(false);
          }}
          onClickFlag={() => {
            setOpenMobileMenu(false);
            open();
          }}
          isEnglish={isEnglish}
        />
      )}

      <div className={`${active === secondMenu[1].length - 1 ? "block" : "hidden"}`}>
        <WebshopHeaderFAQ topMenuIsShown={showTopMenu} faq={faq} isDark={isDark} />
      </div>

      <ConfirmationModal
        className='w-[400px] md:w-[600px] lg:w-[600px] xl:w-[600px]'
        actionButtonOption={{
          title: t("Yes, continue to change")!,
          buttonClass: "px-10",
          onPress: async (e) => {
            e.preventDefault();
            const value = isEnglish ? "de" : "en";
            setLanguage(value);
            changeLanguage(value);
            localStorage.setItem("language", value);
            close();
          },
        }}
        title={`${t("Are you sure you want to change your language to {{lang}}", {
          lang: isEnglish ? "German" : "English",
        })}`}
      />
    </header>
  );
};

export default WebshopHeader;
