import React from "react";

const PolygonIcon = ({ isDark }: { isDark?: boolean }) => {
  return (
    <svg width='52' height='18' viewBox='0 0 52 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M26 0L51.9808 18H0.0192375L26 0Z' fill={isDark ? "#E9EBE8" : "#F7F4EF"} />
    </svg>
  );
};

export default PolygonIcon;
